/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React, { useEffect, createRef, useState } from 'react';
import { useUIDSeed } from 'react-uid';
import {
    Card, Grid, makeStyles, Typography,
} from '@material-ui/core';
import {
    arrayOf, shape, string, number, bool, func,
} from 'prop-types';
import mbpLogger from 'mbp-logger';
import Slider from 'react-slick';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ReactMarkdown from 'react-markdown';
import { useSelector, useDispatch } from 'react-redux';
import ReactHTMLParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import parseFontFamilyExtension from '../../../../helpers/contentstack/parseFontFamilyExtension';
import {
    getPresentationFamily, getPriceRangeLayout, getFeatureFlag, getBrand,
} from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { trackEvent } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import getSkuPriceRange from '../../../../helpers/getSkuPriceRange';
import { getEnterpriseId } from '../../../../../state/ducks/Member/ducks/EnterpriseId/EnterpriseId-Selectors';
import noop from '../../../../helpers/noop';

// components
import customBreakpoints from '../../../../helpers/customBreakpoints';
import { getSSRDeviceType, getUserSubmittedProductFilterZipcode } from '../../../../../state/ducks/App/App-Selectors';
import ProductImage from '../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/ProductImage';
import ProductName from '../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/ProductName';
// import PersonalizedPriceRange from './PersonalizedPriceRange';
import getPriceFromEngine from '../../../../../apis/product-apis/priceEngine';
import ComponentErrorBoundary from './ComponentErrorBoundary/ComponentErrorBoundary';
import PriceBreakDownUi from '../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/PriceBreakDownUi';
import { getIsAuthenticatedStatus, getIsPassportUserLoggedIn } from '../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getPassportData } from '../../../../../state/ducks/Passport/Passport-Selectors';
import validatePassport from '../../../../helpers/validatePassport';
import PriceRange from '../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/PriceRange';
import { getPriceArrays } from '../../../../helpers/DynamicPricing/getPriceArrays';
import { getPassportSubscriptionStatus } from '../../../../../state/ducks/Common/Common-Selectors';

const useStyles = makeStyles((theme) => ({
    title: (styles) => ({
        color: styles?.displayTitleFontColor || '#000000',
        fontFamily: styles?.displayTitleFontFamily || theme?.typography?.fontFamily,
        fontSize: styles?.displayTitleFontSize || '1.625rem',
        fontStyle: styles?.displayTitleFontStyle || 'normal',
        // add other header tags here ONLY if needed for SEO/styling
        '& h3, & p': {
            margin: styles?.displayTitleMargin || '0px',
            padding: '0px',
            fontFamily: styles?.displayTitleFontFamily || theme?.typography?.fontFamily,
            textAlign: 'center',
            fontSize: styles?.displayTitleFontSize || '1.625rem',
            fontWeight: styles?.displayTitleFontWeight || '400',
            letterSpacing: '.4px',
            color: 'inherit',
            [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
                margin: styles?.displayTitleMargin || '0px',
                fontSize: styles?.displayTitleFontSize || '1rem',
                fontWeight: styles?.displayTitleFontWeight || '500',
            },
        },
    }),
    subtitle: (styles) => ({
        color: styles?.displayTitleFontColor || '#000000',
        fontFamily: styles?.displaySubtitleFontFamily || theme?.typography?.fontFamily,
        fontSize: styles?.displaySubtitleFontSize || '20px',
        fontStyle: styles?.displayTitleFontStyle || 'normal',
        fontWeight: styles?.displaySubtitleFontWeight,
        margin: '0px 0px 20px',
        // add other header tags here ONLY if needed for SEO/styling
        '& h3, & p': {
            textAlign: 'center',
            margin: '0px 0px 20px',
            fontFamily: styles?.displaySubtitleFontFamily || theme?.typography?.fontFamily,
            fontSize: styles?.displaySubtitleFontSize || '20px',
            fontWeight: styles?.displaySubtitleFontWeight || '300',
            letterSpacing: '.4px',
            color: 'inherit',
            [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
                fontSize: '1rem',
                fontWeight: '500',
                margin: '0px 0px 16px',
            },
        },
    }),
    compContainer: (styles) => ({
        backgroundColor: styles.displayBackgroundColor,
        padding: '32px 0px',
        [theme.breakpoints.down(600)]: {
            padding: '24px 0px',
        },
    }),

    productContainerWithScroll: {
        display: 'flex',
        margin: '0 auto',
        padding: '0 0 10px 0',
        width: '100%',
        maxWidth: '1400px',
        justifyContent: 'flex-start',
        overflowX: 'auto',
    },
    productContainer: {
        display: 'flex',
        margin: '0 auto',
        padding: '0 0 10px 0',
        width: '100%',
        maxWidth: '1400px',
        justifyContent: 'center',
        '&--space-evenly': {
            justifyContent: 'space-around',
            [theme.breakpoints.down(1025)]: {
                justifyContent: 'space-around',
                overflowX: 'inherit',
            },
        },
        '&--center': {
            justifyContent: 'center',
            [theme.breakpoints.down(1025)]: {
                justifyContent: 'center',
            },
        },
        [theme.breakpoints.down(1025)]: {
            overflowX: 'scroll',
            justifyContent: 'flex-start',
        },

    },
    main: {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        '& .slick-track': {
            display: 'flex',
            justifyContent: 'center',
        },
        '& .slick-slide': {
            display: 'inline-block',
            padding: '0 10px',
            position: 'relative',
        },
    },
    sliderContainer: (props) => ({
        '& .slick-list': {
            overflow: 'inherit',
            margin: '0 0px',
        },
        '& .slick-track': {
            margin: '0 auto',
        },
        '& .slick-prev, & .slick-next': {
            transform: 'translate(0,-50%)',
            cursor: 'pointer',
            border: 'none',
            outline: 0,
            fontSize: 0,
            lineHeight: 0,
            top: '42%',
            width: '16px',
            height: '30px',
            position: 'absolute',
            background: 'transparent',
        },
        '& .slick-prev': {
            left: 0,
        },
        '& .slick-next': {
            right: 0,
        },
        '& .slick-dots': {
            listStyle: 'none',
            display: 'flex !important',
            padding: '0',
            justifyContent: 'center',
            margin: '0',
            '& li': {
                lineHeight: '1',
            },
            '& .slick-active': {
                '& div': {
                    background: theme.palette.cms?.sliderNavDotSelectedBackground || 'rgb(47, 47, 47)',
                },
            },
            '& .slick-current': {
                '& div': {
                    paddingRight: '5px',
                },
            },
        },
        '& .slick-disabled': {
            color: '#cdd0d3',
            cursor: 'default',
        },
        [theme.breakpoints.between(602, 770)]: {
            '& .slick-track': {
                width: props.totalNumberOfProducts <= 3 && '100% !important',
            },
            '& .slick-slide': {
                maxWidth: props.totalNumberOfProducts <= 3 && 'calc(33.3%) !important',
            },
        },

        [theme.breakpoints.up(1000)]: {
            padding: '0 70px',
            '& .slick-prev, & .slick-next': {
                width: '25px',
            },
        },
        [theme.breakpoints.up(600)]: {
            padding: '0 50px',
            '& .slick-prev, & .slick-next': {
                width: '25px',
            },
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0px',
            '& .slick-prev, & .slick-next': {
                width: '25px',
            },
        },
    }),
    slideItem: {
        height: '100%', // full height based on parent
        '& > div.rl-box': {
            position: 'inherit',
        },
        [theme.breakpoints.down(600)]: {
            padding: '0 10px',
        },
    },
    gridContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
    },
    productLink: {
        width: '100%',
        display: 'inline-block',
        textDecoration: 'none',
        color: theme.palette.cms?.black || '#000',
        '& .MuiCard-root': {
            overflow: 'visible',
        },
        [theme.breakpoints.down(600)]: {
            width: '160px',
        },
    },
    imageCard: (styles) => ({
        width: '100%',
        paddingBottom: '109.09%', // DO NOT CHANGE pre-renders height according to img aspect ratio - only change if cdn height/width change
        marginBottom: styles?.isSubcollectionBanner ? '0px' : '8px',
        borderRadius: 0,
        position: 'relative',
        background: styles?.isSubcollectionBanner ? 'none !important' : '#e3e3e3',
        boxShadow: styles?.isSubcollectionBanner ? 'none' : '0 2px 4px 0 rgba(0,0,0,0.2)',
    }),
    productContentWrapperFood: (styles) => ({
        padding: styles?.isSubcollectionBanner ? '0px 10px' : '',
        '& p': {
            lineHeight: '1.3',
            fontSize: '0.875rem',
            [theme.breakpoints.up(769)]: {
                lineHeight: '1.4',
                fontSize: '1rem',
            },
            [theme.breakpoints.down(768)]: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
            },
        },
    }),
    productContentWrapperFlower: {
        '& div': {
            width: '100%',
            '& p': {
                lineHeight: '1.3',
                fontSize: '0.875rem',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                [theme.breakpoints.up(769)]: {
                    lineHeight: '1.4',
                    fontSize: '1.0rem',
                },
            },
            '& $sellerName': {
                fontSize: '0.813rem',
                margin: '0',
                [theme.breakpoints.down(769)]: {
                    fontSize: '0.75rem',
                },
            },
        },
    },
    productListingPrice: {
        color: theme.palette.product?.retailPrice || theme.palette.colorPrimary,
        fontWeight: '700',
        fontSize: '1rem',
    },
    productPrice: {
        fontWeight: 'bold',
    },
    restructureCollection: {
        marginTop: '12px',
        '& [data-component="productName"]': {
            lineHeight: '1.625rem',
            fontSize: '1.125rem',
            '@media only screen and (max-width: 768px)': {
                lineHeight: '1.5rem',
                fontSize: '1rem',
            },
        },
        '& $productPrice': {
            margin: '8px 0px',
            fontWeight: 'normal',
            fontFamily: 'Lato',
            '& span': {
                lineHeight: '1.875rem',
                fontSize: '1.25rem',
                '@media only screen and (max-width: 768px)': {
                    lineHeight: '1.625rem',
                    fontSize: '1.125rem',
                },
            },
        },
    },
    priceContainer: {
        [theme.breakpoints.down(600)]: {
            width: '160px',
        },
    },
    priceSection: {
        whiteSpace: 'normal !important', // to show full price
    },
    removeDropShadow: {
        boxShadow: 'inherit !important',
    },
    container: {
        padding: '32px 0px !important',
        '@media only screen and (max-width: 959.95px) and (min-width: 600px)': {
            padding: '32px 16px !important',
        },
        '@media only screen and (max-width: 1440px) and (min-width: 1279.5px)': {
            padding: '32px 24px !important',
        },
        '& $sliderContainer': {
            [theme.breakpoints.up('600')]: {
                padding: '0px 38px',
            },
            '& .slick-track': {
                margin: '0px',
                justifyContent: 'flex-start',
            },
        },
        '& $title > p': {
            fontSize: '20px',
            lineHeight: '30px',
            textAlign: 'left',
            padding: '0px 16px 16px',
            fontFamily: 'LatoBold',
            [theme.breakpoints.up('600')]: {
                fontSize: '28px',
                lineHeight: '36px',
                padding: '0px 16px 20px 0px',

            },
        },
        '& .transformedName': {
            fontSize: '14px',
            lineHeight: '18px',
            [theme.breakpoints.up('600')]: {
                fontSize: '18px',
                lineHeight: '26px',
            },
        },
        '& .priceWrapper': {
            margin: '4px 0px',
            fontFamily: 'LatoBold',
            '& span': {
                fontSize: '14px',
                lineHeight: '18px',
                [theme.breakpoints.up('600')]: {
                    fontSize: '20px',
                    lineHeight: '30px',
                },
            },
        },
        '& $productContainerWithScroll': {
            padding: '0px 6px',
        },
        '& $productContainer': {
            '&--center': {
                justifyContent: 'flex-start',
            },
            [theme.breakpoints.down('600')]: {
                padding: '0px 6px',
            },
        },
    },
}));

// Smoothing out scroll behavior
let firstClientX;
let clientX;

// eslint-disable-next-line consistent-return
const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
        e.preventDefault();
        e.returnValue = false;

        return false;
    }
};

const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
};

const PersonalizedCarouselBuilder = ({
    blockData,
    blockData: {
        wcs_style,
        products_to_show,
    },
    products,
    nDisplayProducts,
    eventTrackingData: {
        recentlyViewed,
    },
    demandType,
    title,
    productClickCallback,
    isPdpRedesignEnabled,
    isPdpSlider,
}) => {
    // Smooth scroll behavior by managing vertical v horizontal scrolling when customer interacts with recs container
    const containerRef = createRef();
    const dispatch = useDispatch();
    const [dynamicPrices, setDynamicPrices] = useState([]);
    const [displayDynamicPrice, setDisplayDynamicPrice] = useState(false);
    const isDynamicPriceEnabled = useSelector(getFeatureFlag('is-dynamic-pricing-enabled'));
    const isDynamicPriceBatchingEnabled = useSelector(getFeatureFlag('is-price-engine-batching-enabled'));
    const isZipDynamicPricingEnabled = useSelector(getFeatureFlag('is-zip-dynamic-pricing-enabled'));
    const hideDropShadow = useSelector(getFeatureFlag(['is-collection-drop-shadow']));
    const whichCollectionPageRestructure = useSelector(getFeatureFlag('which-collection-page-restructure'));
    const brand = useSelector(getBrand);

    const enterpriseId = useSelector(getEnterpriseId);
    const userSubmittedZip = useSelector(getUserSubmittedProductFilterZipcode);
    const allPartNumbers = products.map((product) => product.partNumber);
    const newPartNumbers = allPartNumbers.map((n) => ({
        partNumber: n,
    }));
    const isPassportUser = useSelector(getIsPassportUserLoggedIn);
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);
    const passportData = useSelector(getPassportData);
    const isPassportMember = validatePassport('isActivePassportUser', passportData);
    const isPassportAdded = useSelector(getPassportSubscriptionStatus);
    let customerTypeValue = [];
    if ((isPassportMember && isPassportUser) || isPassportAdded) {
        customerTypeValue = [{
            name: 'CUSTOMER_TYPE',
            value: 'Passport',
        }];
    } else if (isAuthenticated) {
        customerTypeValue = [{
            name: 'CUSTOMER_TYPE',
            value: 'Registered',
        }];
    }

    const getPrices = (priceData) => {
        const priceArrays = getPriceArrays(priceData);
        if (!priceArrays?.sale?.length && !priceArrays?.retail?.length) {
            return null;
        }
        return priceArrays;
    };

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener('touchstart', touchStart);
            containerRef.current.addEventListener('touchmove', preventTouch, {
                passive: false,
            });
        }

        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener('touchstart', touchStart);
                containerRef.current.removeEventListener('touchmove', preventTouch, {
                    passive: false,
                });
            }
        };
    });

    useEffect(() => {
        async function fetchPriceFromEngine() {
            const payload = {
                enterpriseId,
                products: newPartNumbers,
                variants: customerTypeValue,
            };

            if (isZipDynamicPricingEnabled  && userSubmittedZip) {
                payload.variants.push({
                    name: 'ZIP_CODE',
                    value: userSubmittedZip,
                });
            }

            if (isDynamicPriceEnabled) {
                getPriceFromEngine({}, null, payload, isDynamicPriceBatchingEnabled, true)
                    .then(({ data }) => {
                        setDynamicPrices(data);
                        setDisplayDynamicPrice(true);
                    })
                    .catch((ex) => {
                        setDisplayDynamicPrice(true);
                        mbpLogger.logError({
                            appName: process.env.npm_package_name,
                            jsError: ex,
                            message: `PersonalizedCarouselBuilder: fetch prices failed...${ex.message}`,
                            enterpriseId,
                        });
                    });
            }
        }

        fetchPriceFromEngine();
    }, [
        isDynamicPriceEnabled,
        isDynamicPriceBatchingEnabled,
        isZipDynamicPricingEnabled,
        setDynamicPrices,
        enterpriseId,
        userSubmittedZip,
        isPassportAdded,
    ]);

    const seed = useUIDSeed();
    const deviceType = useSelector(getSSRDeviceType);
    const presentationFamily = useSelector(getPresentationFamily);
    const priceRangeLayout = useSelector(getPriceRangeLayout);
    const isFoodBrand = presentationFamily === 'food';

    let displayTitle = blockData?.title || title;
    if (displayTitle === '') displayTitle = title;

    const styles = {
        displayTitleMargin: blockData?.title_margin,
        displayTitleFontColor: blockData?.title_font_color,
        displayTitleFontFamily: parseFontFamilyExtension(blockData?.title_font_family),
        displayTitleFontSize: blockData?.title_font_size,
        displayTitleFontStyle: blockData?.title_font_style,
        displayTitleFontWeight: blockData?.title_font_weight,
        displaySubtitleFontFamily: parseFontFamilyExtension(blockData?.subtitle_font_family),
        displaySubtitleFontSize: blockData?.subtitle_font_size,
        displaySubtitleFontWeight: blockData?.subtitle_font_weight,
        displayBackgroundColor: blockData?.background_color,
    };
    styles.totalNumberOfProducts = products.length;
    const classes = useStyles(styles);

    const isMobile = deviceType === 'mobile';
    const shouldScrollContainer = products.length > nDisplayProducts || (wcs_style && products.length > nDisplayProducts / 2);
    const display_as_grid_mobile = blockData?.display_as_grid_mobile;

    const handleLinkOnClick = (product) => {
        if (recentlyViewed) {
            const price = getSkuPriceRange(isDynamicPriceEnabled && product?.price ? product?.price : product?.skuPriceRange);
            const eventDataLayer = {
                eventName: 'product_click',
                eventCategory: 'Recently Viewed',
                eventAction: 'Product Click',
                eventLabel: product?.name,
                products: [
                    {
                        name: product?.name,
                        partNumber: product?.partNumber,
                        price: price?.retailPrice || price?.salePrice,
                        categoryName: 'Recently Viewed',
                    },
                ],
            };
            productClickCallback(product?.partNumber);
            dispatch(trackEvent(eventDataLayer));
            if (isPdpSlider) {
                dispatch(trackEvent({
                    eventName: 'feature_interaction',
                    eventCategory: 'Product Page',
                    eventAction: 'Recently Viewed Clicks',
                    feature_detail: `${product?.partNumber} - ${brand.id}`,
                    feature_element: 'Recently Viewed Clicks',
                    feature_category: 'Product Page',
                }));
            }
        }
    };
    /**
     * Reconcile carousel styling for wcs_style flag
     *
     * wcs_style flag makes the iamges significantly larger meanings many adjustments need
     * to be made depending on how many images are in the carousel
     */
    let productUIContainerClass = classes.productContainer;
    // the images arent that big and there arent too many of them so center the images
    // in the carousel
    if (!shouldScrollContainer && !wcs_style && products?.length < 3) {
        productUIContainerClass = `${productUIContainerClass} ${productUIContainerClass}--center`;
    }

    // if the images are large and we arent going to scroll, space them evenly for wcs
    if (!shouldScrollContainer && wcs_style) {
        productUIContainerClass = `${productUIContainerClass} ${productUIContainerClass}--space-evenly`;
    }

    // if we are going to scroll, its going to flex start
    if (shouldScrollContainer) {
        productUIContainerClass = classes.productContainerWithScroll;
    }

    const productsToShow = products_to_show?.desktop || 5;
    const collectionRestructureEnabled = whichCollectionPageRestructure?.toLowerCase() !== 'control';
    if (products?.length > 0 && productsToShow > 0) {
        return (
            <ComponentErrorBoundary function="PersonalizedCarouselBuilder" module="PersonalizedContent">
                <div className={`${classes.compContainer} ${isPdpRedesignEnabled ? classes.container : ''}`}>
                    <div aria-hidden="true">
                        <ReactMarkdown
                            source={displayTitle}
                            skipHtml
                            className={classes.title}
                        />
                        { blockData?.subtitle
                            && (
                                <ReactMarkdown
                                    source={blockData?.subtitle}
                                    skipHtml
                                    className={classes.subtitle}
                                />
                            )}
                    </div>
                    {isMobile ? (
                        <div className={`${productUIContainerClass} ${(display_as_grid_mobile && isMobile) && classes.gridContainer}`}>
                            {products?.map((product) => {
                                const {
                                    name, image, seo: { url }, partNumber, skuPriceRange,
                                } = product;
                                let link = url;
                                // const isRange = skuPriceRange?.sale?.[0]?.value !== skuPriceRange?.sale?.[1]?.value;
                                if (demandType && url) {
                                    link = link.indexOf('?') >= 0 ? `${link}&demandType=${demandType}` : `${link}?demandType=${demandType}`;
                                }
                                let dynamicPricedProductMobile;
                                if (dynamicPrices?.products) {
                                    dynamicPricedProductMobile = dynamicPrices.products.find((dynamicPricedProductMobilePrice) => dynamicPricedProductMobilePrice.partNumber === partNumber);
                                }
                                return (
                                    <div className={classes.slideItem} key={seed(link || name || partNumber)}>
                                        <Link
                                            data-test="personalized-product"
                                            title={`${ReactHTMLParser(name)}`}
                                            to={link}
                                            className={classes.productLink}
                                            onClick={() => {
                                                productClickCallback(partNumber);
                                                handleLinkOnClick(product);
                                            }}
                                        >
                                            <Card className={hideDropShadow ? `${classes.imageCard} ${classes.removeDropShadow}` : classes.imageCard}>
                                                <ProductImage image={image} name={name} />
                                            </Card>
                                            <Grid container direction="column" className={`${(isFoodBrand) ? classes.productContentWrapperFood : classes.productContentWrapperFlower}  ${collectionRestructureEnabled ? classes.restructureCollection : ''}`}>
                                                <Grid item>
                                                    <ProductName isFoodBrand={isFoodBrand} name={name} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={`${isFoodBrand ? classes.productListingPrice : classes.productPrice} priceWrapper ${classes.priceSection}`}>
                                                        {/* <PersonalizedPriceRange
                                                            presentationFamily={presentationFamily}
                                                            priceRangeLayout={priceRangeLayout}
                                                            skuPriceRange={skuPriceRange}
                                                            dynamicPrices={isDynamicPriceEnabled && dynamicPricedProductMobile?.prices?.length ? dynamicPricedProductMobile.prices : null}
                                                            isMobile={isMobile}
                                                            isRange={isRange}
                                                            partNumber={partNumber}
                                                            isDynamicPriceEnabled={isDynamicPriceEnabled}
                                                            displayPrice={isDynamicPriceEnabled ? displayDynamicPrice : true}
                                                            collectionRestructureEnabled={whichCollectionPageRestructure?.toLowerCase() === 'variantb'}
                                                        /> */}
                                                        <PriceRange
                                                            presentationFamily={presentationFamily}
                                                            priceRangeLayout={priceRangeLayout}
                                                            skuPriceRange={isDynamicPriceEnabled && dynamicPricedProductMobile?.prices?.length ? getPrices(dynamicPricedProductMobile.prices) : skuPriceRange}
                                                            displayPrice={isDynamicPriceEnabled ? displayDynamicPrice : true}
                                                            isMobile={isMobile}
                                                            collectionRestructureEnabled={whichCollectionPageRestructure?.toLowerCase() === 'variantb'}
                                                        />
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Link>
                                        <div className={classes.priceContainer}>
                                            <PriceBreakDownUi
                                                dynamicPriceData={
                                                    dynamicPrices?.products?.length > 0 && dynamicPrices?.products?.find((item) => item.partNumber === partNumber) ? dynamicPrices?.products?.find((item) => item.partNumber === partNumber) : null
                                                }
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div ref={containerRef} className={`${productUIContainerClass} ${classes.gridContainer}`}>
                            <Slider
                                dots={false}
                                speed={800}
                                slidesToShow={productsToShow}
                                slidesToScroll={productsToShow}
                                swipe
                                draggable={false}
                                infinite={false}
                                nextArrow={<ArrowForwardIosIcon />}
                                prevArrow={<ArrowBackIosIcon />}
                                className={`
                            ${classes.main}
                            ${classes.sliderContainer}
                        `}
                                responsive={[
                                    {
                                        breakpoint: 769,
                                        settings: {
                                            slidesToShow: products_to_show?.tablet || 3,
                                            slidesToScroll: products_to_show?.tablet || 3,
                                            infinite: products.length >= 3,
                                        },
                                    },
                                ]}
                            >
                                {products?.map((product) => {
                                    const {
                                        name, image, seo: { url }, partNumber, skuPriceRange,
                                    } = product;
                                    let link = url;
                                    // const isRange = skuPriceRange?.sale?.[0]?.value !== skuPriceRange?.sale?.[1]?.value;
                                    if (demandType && url) {
                                        link = link.indexOf('?') >= 0 ? `${link}&demandType=${demandType}` : `${link}?demandType=${demandType}`;
                                    }
                                    let dynamicPricedProductDesktop;
                                    if (dynamicPrices?.products) {
                                        dynamicPricedProductDesktop = dynamicPrices.products.find((dynamicPricedProductDesktopPrice) => dynamicPricedProductDesktopPrice.partNumber === partNumber);
                                    }
                                    return (
                                        <div className={classes.slideItem} key={seed(link || name || partNumber)}>
                                            <Link
                                                data-test="personalized-product"
                                                title={`${ReactHTMLParser(name)}`}
                                                to={link}
                                                className={classes.productLink}
                                                onClick={() => {
                                                    productClickCallback(partNumber);
                                                    handleLinkOnClick(product);
                                                }}
                                            >
                                                <Card className={hideDropShadow ? `${classes.imageCard} ${classes.removeDropShadow}` : classes.imageCard}>
                                                    <ProductImage image={image} name={name} />
                                                </Card>
                                                <Grid container direction="column" className={`${(isFoodBrand) ? classes.productContentWrapperFood : classes.productContentWrapperFlower} ${collectionRestructureEnabled ? classes.restructureCollection : ''}`}>
                                                    <Grid item>
                                                        <ProductName isFoodBrand={isFoodBrand} name={name} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className={`${isFoodBrand ? classes.productListingPrice : classes.productPrice} priceWrapper ${classes.priceSection}`}>
                                                            {/* <PersonalizedPriceRange
                                                                presentationFamily={presentationFamily}
                                                                priceRangeLayout={priceRangeLayout}
                                                                skuPriceRange={skuPriceRange}
                                                                dynamicPrices={isDynamicPriceEnabled && dynamicPricedProductDesktop?.prices?.length ? dynamicPricedProductDesktop.prices : null}
                                                                isMobile={isMobile}
                                                                isRange={isRange}
                                                                partNumber={partNumber}
                                                                isDynamicPriceEnabled={isDynamicPriceEnabled}
                                                                displayPrice={isDynamicPriceEnabled ? displayDynamicPrice : true}
                                                                collectionRestructureEnabled={whichCollectionPageRestructure?.toLowerCase() === 'variantb'}
                                                            /> */}
                                                            <PriceRange
                                                                presentationFamily={presentationFamily}
                                                                priceRangeLayout={priceRangeLayout}
                                                                skuPriceRange={isDynamicPriceEnabled && dynamicPricedProductDesktop?.prices?.length ? getPrices(dynamicPricedProductDesktop.prices) : skuPriceRange}
                                                                displayPrice={isDynamicPriceEnabled ? displayDynamicPrice : true}
                                                                isMobile={isMobile}
                                                                collectionRestructureEnabled={whichCollectionPageRestructure?.toLowerCase() === 'variantb'}
                                                            />
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Link>
                                            <PriceBreakDownUi
                                                dynamicPriceData={
                                                    dynamicPrices?.products?.length > 0 && dynamicPrices?.products?.find((item) => item.partNumber === partNumber) ? dynamicPrices?.products?.find((item) => item.partNumber === partNumber) : null
                                                }
                                            />
                                        </div>
                                    );
                                })}
                            </Slider>
                        </div>
                    )}
                </div>
            </ComponentErrorBoundary>
        );
    }

    return null;
};

PersonalizedCarouselBuilder.propTypes = {
    products: arrayOf(shape({
        id: string.isRequired,
        image: shape({
            path: string.isRequired,
            name: string.isRequired,
        }).isRequired,
        name: string.isRequired,
        skuPriceRange: shape({
            retail: arrayOf(shape({
                value: number.isRequired,
            })).isRequired,
            sale: arrayOf(shape({
                value: number.isRequired,
            })).isRequired,
        }).isRequired,
        seo: shape({
            url: string.isRequired,
        }).isRequired,
    })).isRequired,
    nDisplayProducts: number.isRequired, // break point between scrolling/non-scrolling

    /**
     * This is variables coming from the homepage and SSR.
     * To make this component work elsewhere, it is no longer
     * marked as required but is required for anything on the homepage.
     *
     * Currently, that includes:
     * - Trending Products Carousel
     * - ProductRecommendationBuilder
     * - RecentlyViewedProductsCarouselBuilder in file child of RecentlyViewedProductsCarouselContainer
     *
     * For other components, use title and titleFontColor props.
     */
    blockData: shape({
        title: string,
        title_margin: string,
        title_font_color: string,
        title_font_size: string,
        title_font_style: string,
        // title_font_family is the value of the Font extension value from CMS, needs to be parsed
        title_font_family: string,
        display_as_grid_mobile: bool,
    }),
    title: string,
    demandType: string,
    eventTrackingData: shape({
        productName: string,
    }),
    productClickCallback: func,
    isPdpRedesignEnabled: bool,
    isPdpSlider: bool,
};

PersonalizedCarouselBuilder.defaultProps = {
    blockData: {
        title: '',
        title_margin: '',
        title_font_color: '',
        title_font_size: '',
        title_font_style: '',
        title_font_family: '',
        display_as_grid_mobile: false,
    },
    // 4810; title can be empty, removed default 'Other Products'
    title: '',
    demandType: '',
    eventTrackingData: {
        productName: '',
    },
    productClickCallback: noop,
    isPdpRedesignEnabled: false,
    isPdpSlider: false,
};

export default PersonalizedCarouselBuilder;
